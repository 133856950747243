import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from '../constants/common';
// import { getAuthToken } from '../../utils';

export const onSuccessRequestCallback = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    // if (!newConfig.headers) {
    //     newConfig.headers = {};
    // }
    //
    // if (IS_DEV && !newConfig.headers.Authorization && getAuthToken()) {
    //     newConfig.headers.Authorization = `Bearer ${getAuthToken()}`;
    // }

    if (!newConfig.headers['Content-Type']) {
        newConfig.headers['Content-Type'] = 'application/json';
    }

    return newConfig;
};

export const onSuccessRequestJwtCallback = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    if (typeof window !== 'undefined') {
        var jwt = localStorage.getItem('bbuddy_token_test');

        if(jwt) {
            newConfig.headers.set('Authorization', `Bearer ${jwt}`);
        }   
    } 
    return newConfig;
};

export const onSuccessResponseJwtCallback = (response: AxiosResponse) => {    
    var header = response.headers['x-new-token'];
    if(header) {
        localStorage.setItem('bbuddy_token_test', header);
    }
    return response;
};

export const onSuccessResponseCallback = (response: AxiosResponse) => response;

export const onErrorResponseCallback = (error: any) => Promise.reject(error);

export const apiClient = axios.create({
    baseURL: BASE_URL
});

apiClient.interceptors.request.use(onSuccessRequestCallback);

apiClient.interceptors.response.use(
    onSuccessResponseCallback,
    onErrorResponseCallback
);

apiClient.interceptors.response.use(onSuccessResponseJwtCallback);
apiClient.interceptors.request.use(onSuccessRequestJwtCallback);
